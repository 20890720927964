.CardHistoricoAcolhimentoPrimeiroAcesso{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin: 15px 5px;
}

.HeaderHistoricoAcolhimentoPrimeiroAcesso{
    padding: 10px 20px;
    background: #F4F6F8;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    text-align: left;
    
}

.HeaderHistoricoAcolhimentoPrimeiroAcesso p{
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #45515B!important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.InputBuscaporAnjoeFiltro.css-9wn881{
    width: 95%;
}

.InputSearchHistoricoAcolhimentoPrimeiroAcesso{
    padding: 10px 20px;
}

.MainHistoricoAcolhimentoPrimeiroAcesso{
    padding: 15px 35px;
}

.CardAzulDashInicialVersaoDesktop{
    background: linear-gradient(135deg, #3366FF 0%, #3366FF 100%);
    border-radius: 16px;
    padding: 25px;
    margin: 20px 0px;
    text-align: left;
}

.CardAzulDashInicialVersaoDesktop .TituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 600!important;
    font-size: 24px!important;
    line-height: 32px!important;
}

.CardAzulDashInicialVersaoDesktop .SubtituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    margin: 13px 0;
}

.CardAzulDashInicialVersaoDesktop .BtnCardAzulDashInicial{
    background: #FFFFFF;
    border-radius: 8px;
    color: #212B36;
    padding: 10.5px 14px;
}