.CardQuiz{
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    padding: 8px!important;
}

.ImgCardQuiz{
    height: inherit;
    border-radius: 16px;
    object-fit: cover;
}

@media (max-width: 767px){
    .CardQuiz > div{
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
    }
}