.CardAtendimentoAndamentoDash {
  margin: 16px 4px 0px 4px;
  background: linear-gradient(135deg, rgba(104, 205, 249, 0.2), rgba(7, 141, 238, 0.2))
    rgb(255, 255, 255);
  padding: 40px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CardAtendimentoAndamentoDash img {
  width: 50%;
  position: relative;
  top: -60px;
  margin-bottom: -30px;
}
