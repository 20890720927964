.TopoFimAtendimento{
    position: absolute;
    top: 10px;
    left: 10px; 
    width: 100%;
    display: flex;
    width: auto;
    align-items: center;
}

.TitulochatAnjoChegou {
    width: 85%;
    margin: 10px 0 !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    text-align: center !important;
    color: #45515B !important;
}
.imgFimAtendimento{
    width: 24rem;
}

.DialogFimAtendimentoGeral{
    height: 100%;
    display: flex!important;
    flex-direction: column!important;
}

.imgFimdeAtendimentoItem, .TituloFimAtendimentoItem{
    display: flex;
    justify-content: center;
    align-items: center;
}


.ButtonSalaDeEsperaAnjoChegou{
    text-transform: none!important;
}

@media (max-width: 767px){
    .imgFimAtendimento{
        width: 60vw;
    }
    .TitulochatAnjoChegou{
        width: 100%;
        padding: 0px!important;
        margin: 0px!important;
    }
}


@media (max-width: 350px){
    .imgFimAtendimento{
        width: 50vw;
    }
    .TitulochatAnjoChegou{
        width: 100%;
        font-size: 14px!important;
        line-height: 1.3!important;
        padding: 0px!important;
        margin: 0px!important;
    }
}