.dialogAgendamento .MuiGrid-root.MuiGrid-container.css-1643708-MuiGrid-root, .dialogAgendamento .MuiDialog-paperFullScreen{
    background:#ffffff;
}

.dialogAgendamento .MuiDialog-paperFullScreen {
    display: flex;
    justify-content: center;
}

.geralAgendamentoAtendenteOnOff{
    height: 85%;
}

.CardGeralConversar {
    background: transparent!important;
    border: 2px solid rgba(255,255,255, 0.2);
    box-shadow: 0px 4px 11px rgba(145, 158, 171, 0.2), 0px 18px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 8px;
    margin: 10px 0;
    padding: 25px 25px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.dialogGeralMobileAtendenteOnOff{
    background: transparent!important;
    border: 2px solid rgba(255,255,255, 0.2);
    box-shadow: 0px 4px 11px rgba(145, 158, 171, 0.2), 0px 18px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 8px;
    margin: 0px;
    padding: 25px 25px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    height: 100%;
}

.dialogGeralMobileCardConfirmacaoDescontoPacote{
    background: transparent!important;
    border: 2px solid rgba(255,255,255, 0.2);
    box-shadow: 0px 4px 11px rgba(145, 158, 171, 0.2), 0px 18px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 8px;
    margin: 0px;
    padding: 25px 25px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CardConfirmacaoHorario{
    width: 100%!important;
    padding: 15px!important;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
}

.CardConfirmacaoHorario p{
    color: #212B36;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}



.geralAgendamentoAtendenteOnOff .buttonsConversar{
    display: flex;
    align-items: center;
    justify-content: center;
}

.CardConfirmacaoHorario .chipConfirmacaoHorario{
    border-radius: 8px;
    border: 1px solid var(--components-button-outlined, rgba(145, 158, 171, 0.32));
    color: #212B36;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.HorariosSugeridosTitulo{
    color:  #637381!important;
    font-family: Public Sans!important;
    font-size: 12px!important;
    font-style: normal!important;
    font-weight: 700!important;
    line-height: 18px!important;
    text-transform: uppercase!important;
    text-align: left!important;
}

.topoAgendamento{
    position: absolute;
    top: 1rem;
}

.sugestaoDoAtendenteHorario{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sugestaoDoAtendenteHorarioTitulo{
    color: #637381!important;
    text-align: left!important;
    font-family: Public Sans!important;
    font-size: 16px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: 24px!important;
}

.sugestaoDoAtendenteHorario p{
    color:  #212B36!important;
    font-size: 20px!important;
    font-style: normal!important;
    font-weight: 700!important;
    line-height: 36px!important;
}

.TopoAgendamentoProfile{
    padding: 2rem;
    display: flex;
    align-items: center;
}

.FotoeNomeAtendenteAgendamento{
    display: flex;
    align-items: center;
}

.btnAgendamento{
    margin: .4rem 0!important;
    text-transform: none!important;
}

.opcoesHorarios{
    max-width: 100%;
}


.tabsHorarios{
    max-width: 90vw;
    margin: 0!important;
    padding: 0!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    overflow-x: scroll!important;
}

.HorariosAgendamentoTabs  .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX{
    overflow-x: scroll!important;
}

.tabsHorariosConfirmacao{
    width: 100%!important;
}

.HorariosAgendamentoTabs button{
    border-radius: 8px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    width: 4rem!important;
    margin-right: 0.8rem!important;
}

.HorariosAgendamentoTabs span{
    display: none;
}

.HorariosAgendamentoTabs button.Mui-selected{
    background-color: #36f;
    color: #fff!important;
}

.horariosAgendamento, .weekly-calendar{
    margin: 15px 0;
    padding: 1.4rem;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
}

.MesesdoAno{
    text-transform: capitalize!important;
}

.navigation{
    display: flex;
    margin-bottom: 10px;
}

.week, .weeksName{
    display: flex;
    justify-content: space-between;   
    height: 20px
}


.weekName, .day{
    text-align: center;
    width: 25px;
    z-index: 1;
}

.weekName{
    color: #bababa!important;
    font-size: 12px;
}

.current-day{
    background-color: #36f;
    color: #fff!important;
    border-radius: 25px;
    position: relative;
    height: 48px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
    top: -25px;
}