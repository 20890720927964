.TopoStepperAtendimentosPacotes span.MuiStepLabel-label.Mui-active.css-1kt0s4l-MuiStepLabel-label,
.TopoStepperAtendimentosPacotes .css-1kt0s4l-MuiStepLabel-label{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fd; /* #f8f9fd */
    padding: 12px 16px;
    border-radius: 8px;
    color: #212B36;
    margin: 0px;
}

.TopoStepperAtendimentosPacotes.Ativo span.MuiStepLabel-label.Mui-active,
.TopoStepperAtendimentosPacotes.Ativo .css-1kt0s4l-MuiStepLabel-label{
    background: linear-gradient(90deg, #56AEED, #078dee) !important;
    color: #fff!important;
    border-radius: 16px 16px 0px 0px!important;
    padding: 12px 16px!important;
}


.DataHoraAtendimentosPacotes{
    display: flex;
    align-items: center;
    color: #637381;
}

.BotoesAcaoAtendimentosPacotes{
    display: flex;
    flex-direction: row;
}

.TopoStepperAtendimentosPacotes button{
    margin-left: 5px!important;
    margin-top: 0px;
}

.TopoStepperAtendimentosPacotes.Ativo .DataHoraAtendimentosPacotes{
    color: #fff;
}

.StepperAtendimentosPacotes .MuiStepLabel-vertical{
    padding: 0px;
}

.ConteudoStepAtendimentosPacotesAtivo{
    padding-right: 0px!important;
    border-left: 1px solid rgba(145, 158, 171, 0.24)!important;
}

.ConteudoStepAtendimentosPacotesAtivo .MuiCollapse-wrapper.MuiCollapse-vertical.css-smkl36-MuiCollapse-wrapper{
    display: flex;
    flex-direction: column;
}

.ConteudoStepAtendimentosPacotesAtivo .MuiCollapse-wrapperInner{
    background:  linear-gradient(90deg, #56AEED, #078dee) !important;
    color: #fff;
    padding-left: 15px!important;
    border-radius: 0px 0px 16px 16px;
    position: relative;
    width: calc(100% + 1px) !important;
    left: -1px;
}


@media (max-width: 767px){
    .BotoesAcaoAtendimentosPacotes{
        display: flex;
        flex-direction: column;
    }
}