.TituloSeusAtendimentos{
    font-weight: 700!important;
    font-size: 20px!important;
    color: #212B36!important;
}

.InputPesquisaAvaliacao .css-dms7x9{
    margin: 8px 0px!important;
}

.Avaliacao .MuiBox-root.css-d0uhtl{
    display: none!important;
}

.Avaliacao .css-1g8nt3c{
    transform: scale(1)!important;
    scale: 1!important;
    border: 1px solid rgba(145,158,171,.16)!important;
    border-radius: 6px!important;
    padding: 4px 8px!important;
}