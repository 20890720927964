.ItemDetalhesPacote{
    display: flex;
    align-items: center;
    padding: 5px 0px;


}

.ItemDetalhesPacote h6{
    margin-left: 6px;
}

.ItemDetalhesPacoteLista{
    display: flex;
    align-items: center;
    padding: 7px 0px;
}

.ItemDetalhesPacoteLista svg{
    margin-right: 4px;
    color: #00AB55;
}