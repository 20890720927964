.btn-enviar:hover{
    background-color: #103996;
}

.avatares-comunidade{
    transition-duration: 0.4s;
}

.avatares-comunidade:hover{
    transform: scale(1.1);
}

.avatar-clicked {
    border: 2px solid #36B37E;
    transform: scale(1.2);
}