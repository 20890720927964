.buttonNotification{
    background: #3366FF;
    border-radius: 8px;
    width: 100%;
    color: #fff;
    border: solid 2px #3366FF;
    padding: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    transition-duration: 0.5s;  
    font-size: 14px;
    font-weight: 700;
}

.cardNotificacaoAnjoNaoChegou{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
}

.containerInternoNotificaAudio{
    display: flex;
    overflow: hidden;
    padding: 20px 20px;
    flex-direction: column;
    justify-content: space-evenly;
}

.BtnFecharNotificaAudio .iconButtonNotification{
    color: #212B36;
    width: 100%;
    margin: 0;
}

.containerInternoNotificaVideo{
    display: flex;
    overflow: hidden;
    padding: 20px 20px;
    flex-direction: column;
    justify-content: space-evenly;
}

.textoChamadaEncerradaNotificaAudio{
    margin: 20px;
    text-align: center;
}

.InfosChamadaNotificaAudio,
.InfosChamadaNotificaVideo{
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.GlobalNotificaVideo, .GlobalNotificaAudio{
    overflow: hidden;
}

.css-nfmcse-MuiPaper-root-MuiDialog-paper.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody{
    overflow-x: hidden;
}


.CTAsSalaDeEsperaAnjoNaoChegou{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.FooterNotification{
    text-align: center;
}

.BtnFecharNotificaAudio .iconButtonNotification{
    color: #212B36;
}

.buttonNotification:hover{
    background: #fff;
    color: #3366FF;
    border: solid 2px #3366FF;
}

.iconButtonNotificationGreen{
    color: #00AB55;
}

.iconButtonNotification, .iconButtonNotificationGreen{
    margin-right: 10px;
}

.ComoFuncionaFooterNotificaAudio{
    margin-bottom: 1rem!important;
}

.BtnFecharNotificaAudio {
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
}

.BotaoFecharNotificaAudio {
    width: 35px;
    height: 35px;
    background: #DFE3E8;
    box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
    border-radius: 50px;
    border: none;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}