.inputmasktelefone{
    height: 55px;
    padding: 16.5px 14px;
    border-radius: 8px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    font-size: 16px;
    font-family: inherit;
    margin-top: -3px;
}


.inputmasktelefone:focus{
    border: 1px solid #CCC!important;
}

.inputmasktelefone:hover{
    border: 1px solid #CCC!important;
}
.inputmasktelefone::placeholder {
    color: #98a6b0;
}

.inputmasktelefoneerro{
    height: 55px;
    padding: 5px;
    border-radius: 12px;
    border: 1px solid #FF5630!important;
    font-size: 16px;
}
.inputmasktelefoneerro::placeholder {
    color: #FF5630!important;
    padding-left: 10px;
}
.inputmasktelefoneerro:focus{
    border: 1px solid #FF5630!important;
}
.inputmasktelefoneerro:hover{
    border: 1px solid #FF5630!important;
}
.FormHelperTexttelefone{
   margin-left: 15px!important;
}

.inputmasktelefoneerro2  .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: 1px solid #FF5630!important;
    color: #FF5630!important;
}