.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root {
    /*width: 100vw!important;
    max-width: 100vw;*/
    width: 99vw;
    max-width: 100vw;
    margin: .5%!important;
    border-radius: 20px;
}

.css-10pl5hw{
    height: 100vh;
    width: 100vw;
    /*background-image: url('./img/pessoa_call.png');*/
    background-repeat: no-repeat;
    background-size: cover;
}

.botaoSaladeVideo{
    background: #dfe3e8;
    border: none;
    border-radius: 50px;
    box-shadow: 0 8px 16px rgba(145,158,171,.16);
    color: #212B36;
    height: 40px;
    padding: 0px;
    width: 40px;
}



.EncerrarBotaoVideo{
    background: #FF5630;
    border-radius: 8px;
    border: none;
    width: 50%;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

.GlobalChamadaEncerradaVideo{
    padding: 0;
}

.GlobalChamadaEncerradaVideo .css-c4i6sg{
    width: 100vw!important;
}

.botaoFooterSaladeVideo{
    background-color: transparent;
    padding: 15px;
    border-radius: 50%;
    border: 1px solid #fff;
    color: #fff;
    transition-duration: 0.8s;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

div#subscriber {
    position: absolute!important;
    left: 0;
    top: 0;
}

div:has(> .botaoFooterSaladeVideoTurnOff) {
    background: transparent;
}

div:has(> .botaoFooterSaladeVideo) {
    background: transparent;
}

.ItemsHeaderVideo .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root{
    color: #637381;
}


div:has(> .rodapeAtivoVideoItems){
    background: rgba(33, 43, 54, 0.25);
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
    border-radius: 0px 0px 8px 8px;
}

.botaoFooterSaladeVideo:hover{
    transform: rotate(360deg);
    background-color: #2f90ff;
    color: #fff;
    border: 1px solid #2f90ff;
}

.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root{
    overflow: hidden;
}

.botaoFooterSaladeVideoTurnOff{
    background-color: #FF5630;
    padding: 15px;
    border-radius: 50%;
    border: none;
    color: #fff;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.botaoFooterSaladeVideoTurnOff:hover{
    transition-duration: 0.8s;
    background-color: #b02606;
    transform: translateY(-10px) scale(1.1);
}

.css-17nit7w-MuiPaper-root-MuiDialog-paper, .css-19r6kue-MuiContainer-root{
    margin: 0!important;
}

.css-19r6kue-MuiContainer-root{
    padding: 0!important;
}

.MinhaCam{
    position: absolute;
    width: 20rem;
    border-radius: 15px;
    height: 30rem;
    display: inline-flex;
    transition-duration: 0.8s;
    -webkit-box-shadow: 0px 3px 0px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 3px 0px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 3px 0px 1px rgba(0,0,0,0.3);
}

.MinhaCam:hover{
    transform: scale(1.1);
}

.MinhaCamAtiva{
    position: absolute;
    bottom: 22vh;
    right: 5vw;
    z-index: 100;
    width: 6rem;
    border-radius: 15px;
    height: 9rem;
    display: inline-flex;
    justify-content: center;
    transition-duration: 0.8s;
}

.MinhaCamAtiva:hover{
    transform: scale(1.03);
}

.BarraMicrofoneAtivo1,.BarraMicrofoneAtivo2,.BarraMicrofoneAtivo3{
    background-color: #fff;
    z-index: 1;
    position: absolute;
    border-radius: 4px;
}

.BarraMicrofoneAtivo1{
    width: 4px;
    height: 15px;
    bottom: 10px;
    right: 10px;
}

.BarraMicrofoneAtivo2{
    width: 4px;
    height: 10px;
    bottom: 10px;
    right: 18px;
}

.BarraMicrofoneAtivo3{
    width: 4px;
    height: 5px;
    bottom: 10px;
    right: 26px;
}

.TextosAguardando {
    margin: auto;
    width: 100%;
    text-align: center;
    display: contents;
}

.TituloAguardando{
    position: absolute;
    z-index: 1;
    top: 7%;
    margin: auto;
    text-align: center;
    font-size: 18px!important;
    font-weight: 700!important;
    color: #45515B!important;
}


.DesejaEncerrarChamadaVideo {
    font-weight: 700!important;
    font-size: 24px!important;
    line-height: 36px;
    text-align: center;
    color: #45515B;
    padding: 20px;
}

.textoChamadaEncerradaVideo{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #637381;
    margin: 40px 0px;
}

.SubTituloAguardando{
    position: absolute;
    z-index: 1;
    top: 10%!important;
    margin: auto;
    text-align: center;
    font-size: 16px!important;
    font-weight: 400!important;
    color: #637381!important;
}

.SeuNome{
    position: absolute;
    z-index: 1;
    bottom: 15%!important;
    margin: auto;
    text-align: center;
    font-size: 14px!important;
    font-weight: 400!important;
    color: #637381!important;
}

.GreyMaior{
    position: absolute;
    width: 40rem;
    border-radius: 50%;
    background: #EAEAEA;
    height: 40rem;
    display: inline-flex;
    justify-content: center;
    animation: crescendo 1s alternate infinite ease-in;
}

.GreyMenor{
    position: absolute;
    width: 30rem;
    border-radius: 50%;
    background: #CCCCCC;;
    height: 30rem;
    display: inline-flex;
    justify-content: center;
    animation: crescendo 1s alternate infinite ease-in;
}

@keyframes crescendo {
    0%   {transform: scale(1);}
    100% {transform: scale(1.2);}
  }

.css-c4i6sg{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.headervideo{
    width: 100%;
    position: fixed;
    z-index: 100;
    padding: 10px;
}

.headervideo .css-48h5p0{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headervideo .css-48h5p0 .css-1nky8vc {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.MuiContainer-root.MuiContainer-maxWidthXl.GlobalChamadaEncerradaVideo.css-1ekb41w .MuiBox-root.css-c4i6sg{
    position: absolute;
    left: 0px;
}

.rodapeVideo{
    width: 100%;
    position: fixed;
    z-index: 1;
    padding: 10px;
    bottom: 0px;
    background-color: transparent;
}

.rodapeAtivoVideo{
    width: 99%;
    margin: auto;
    position: fixed;
    z-index: 1;
    padding: 10px;
    bottom: 0px;
    background-color: #00000040;
    left: 0.5%;
    margin-bottom: .5%;
    border-radius: 0px 0px 20px 20px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root{
    background: transparent;
}
.publishercss{
    width: 100%;
    border-radius: 15px;
}

@media (max-width: 767px){

    .EncerrarBotaoVideo{
        background: #FF5630;
        border-radius: 8px;
        border: none;
        width: 90%;
        display: inline-flex;
        padding: 10px;
        justify-content: center;
        color: #fff;
        position: absolute;
        bottom: 5%;
        cursor: pointer;
    }
    .GreyMaior{
        position: absolute;
        width: 20rem;
        border-radius: 50%;
        background: #EAEAEA;
        height: 20rem;
        display: inline-flex;
        justify-content: center;
        animation: crescendo 1s alternate infinite ease-in;
    }

    .GlobalChamadaEncerradaVideo .containerInterno {
        margin-top: -40px!important;
    }
    
    .GreyMenor{
        position: absolute;
        width: 15rem;
        border-radius: 50%;
        background: #CCCCCC;;
        height: 15rem;
        display: inline-flex;
        justify-content: center;
        animation: crescendo 1s alternate infinite ease-in;
    }

    .css-10pl5hw{
        height: 100vh;
        width: 100vw;
        /*background-image: url('./img/pessoa_call.png');*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
        #publisher{
            width: 100%;
            border-radius: 15px;
        }

    .publishercss{
            width: 100%;
            border-radius: 15px;
        }

    .MinhaCam{
        position: relative;
        width: 15rem;
        border-radius: 15px;
        background-position: center;
        background-size: cover;
        height: 20rem;
        display: inline-flex;
        justify-content: center;
        transition-duration: 0.8s;
    }

    .MinhaCam:hover{
        transform: rotate(3deg);
    }

    .TituloAguardando{
        position: absolute;
        z-index: 1;
        top: 9%;
        margin: auto;
        text-align: center;
        font-size: 18px!important;
        font-weight: 700!important;
        color: #45515B!important;
    }
    
    .SubTituloAguardando{
        position: absolute;
        z-index: 1;
        top: 12%!important;
        margin: auto;
        text-align: center;
        font-size: 18px!important;
        font-weight: 400!important;
        color: #637381!important;
    }
    
    .SeuNome{
        position: absolute;
        z-index: 1;
        bottom: 14%!important;
        margin: auto;
        text-align: center;
        font-size: 18px!important;
        font-weight: 400!important;
        color: #637381!important;
    }
}