.CardInfoAtendente {
    background: linear-gradient(135deg, #078dee, #0351ab)!important;
    border-radius: 16px;
    color: #fff!important;
    overflow: visible!important;
}

.CardAtendimentosComprados {
    background: linear-gradient(135deg, #FFAB00, #F6A400)!important;
    border-radius: 16px;
    color: #fff!important;
    overflow: visible!important;
}

.CardAtendimentosUtilizados{
    background: linear-gradient(135deg, #ED6907, #E56707)!important;
    border-radius: 16px;
    color: #fff!important;
    overflow: visible!important;
}

.imgCardDetalhesPacotes{
    position: relative;
    top: -60px;
}