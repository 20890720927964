.CardPlanosPagamentos, .CardPixDesktop{
    background: #fff;
    box-shadow: 0px 4px 11px rgba(145, 158, 171, 0.2), 0px 18px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 8px;
    margin: 10px 0;
    padding: 25px 25px;
    backdrop-filter: blur(10px);
    border-bottom: 2px solid rgba(255,255,255, 0.2);
}

.NumeroDeAtendimentos{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b5b5b5;
}

.CardPixDesktop{
    width: 50vw;
}

.NomePlanoPagamento{
    text-align: left;
    font-weight: 600!important;
    color: #212B36!important;
}

.TituloTopoPagamento {
    margin-left: 15px!important;
}

.TopoPaginaPagamento, .TopoMetodosdePagamento{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}


.TopoPaginaPagamento .IconBackTopoPagamento,
.TopoMetodosdePagamento .IconBackTopoPagamento{
    width: 25px;
    height: 25px;
}

.OpcoesTiposdePagamentoTopo{
    margin: 0px 15px!important;
}

.CardPlanoPagamentoItem{
    padding: 0!important;
    margin: 0!important;
}

.ValorPlanoPagamento{
    text-align: left;
    font-weight: 700!important;
    font-size: 24px!important;
    color: #3366FF!important;
}

.ValorPlanoPagamentoPacote{
    text-align: left;
    font-weight: 700!important;
    font-size: 32px!important;
}

.ValorPlanoPagamentoGratis{
    text-align: left;
    font-weight: 700!important;
    font-size: 24px!important;
    color: #00AB55!important;
}

.ItensPlanosListaPagamento{
    display: inline-flex;
    margin: 2px 0;
}

.ItensPlanosListaPagamento svg{
    color: #00AB55;
    width: 11px;
}

.ItensPlanosListaPagamento p{
    font-weight: 400!important;
    font-size: 12px!important;
    color: #212B36!important;
}

.ItensPlanosListaPagamentoPacotes{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: 5px 0px;
}

.ItensPlanosListaPagamentoPacotes svg{
    color: #00AB55;
    width: 18px;
}

.ItensPlanosListaPagamentoPacotes p{
    font-weight: 400!important;
    font-size: 12px!important;
    color: #212B36!important;
}

.PlanoPopularPagamentos {
    color: #00AB55;
    font-weight: 700;
    font-size: 12px;
    background: rgba(0, 171, 85, 0.16);
    border-radius: 6px;
    padding: 4px;
}

.PlanoPopularPagamentosPacoteAzul {
    color: #2065D1;
    font-weight: 700;
    font-size: 12px;
    background: rgba(32, 101, 209, 0.16);
    border-radius: 6px;
    padding: 4px;
}

.PlanoPopularPagamentosPacoteVerde {
    color: #36B37E;
    font-weight: 700;
    font-size: 12px;
    background: rgba(54, 179, 126, 0.16);
    border-radius: 6px;
    padding: 4px;
}

.PlanoPopularPagamentosPacoteAmarelo {
    color: #ffab00;
    font-weight: 700;
    font-size: 12px;
    background: rgba(255, 171, 0, 0.16);
    border-radius: 6px;
    padding: 4px;
}

.PlanoPopularPagamentosPacoteCiano {
    color: #00B8D9;
    font-weight: 700;
    font-size: 12px;
    background: rgba(0, 184, 217, 0.16);
    border-radius: 6px;
    padding: 4px;
}

.PlanoPopularPagamentosPacoteVermelho {
    color: #FF5630;
    font-weight: 700;
    font-size: 12px;
    background: rgba(255, 86, 48, 0.16);
    border-radius: 6px;
    padding: 4px;
}

.TituloContribuirPagamento{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 24px!important;
    line-height: 36px!important;
    text-align: center!important;
    color: #45515B!important;
}

.SubTituloContribuirPagamento{
    margin-top: 15px!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 24px!important;
    text-align: center!important;
    color: #45515B!important;
}

.SelecioneValorContribuirPagamento{
    margin: 15px 0px!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 16px!important;
    line-height: 24px!important;
    text-align: center!important;
    color: #45515B!important;
}

.BotaoMetodoPagamentoContribuir{
    margin: 10px 5px!important;
    width: -webkit-fill-available;
    height: 3.8rem;
    font-size: 18px!important;
}

.opcoesFormadePagamento{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 8px;
    padding: 5px 15px;
    margin: 0px 5px!important;
}

.opcoesFormadePagamento input[type="text"],
.opcoesFormadePagamento input[type="number"] {
    width: 100%;
    font-size: 14px!important;
    padding: 10px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
    color: #212B36;
    margin: 0!important;
    height: 2.8rem;
}
.opcoesFormadePagamento select {
    width: 100%;
    font-size: 14px!important;
    padding: 10px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
    color: #212B36;
    margin: 0!important;
    height: 2.8rem;
}

.DetalhesPagamentoTopico{
    text-align: left;
}

.ItemBotaoMetodoPagamentoContribuir{
    width: 100%;
}

.TipoPagamentoNome, .DetalhesPagamentoTopico{
    float: left;
    text-align: left;
}

.TipoPagamentoNome p {
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
    color: #212B36!important;
}

.inputCPFDialogPagamento{
    border: 1px solid rgba(145,158,171,.32);
    border-radius: 8px;
    font-family: inherit;
    font-size: 16px;
    height: 55px;
    margin-top: 10px;
    padding: 16.5px 14px;
}

.DetalhesPagamentoValor{
    float: right;
    text-align: right;
}

.DetalhesPagamentoValorDesconto{
    float: right;
    text-align: right;
    font-style: normal;
    font-weight: 700!important;
    font-size: 16px!important;
    color: #f44336!important;
    text-decoration: line-through;
}

.IconTextoTipoPagamento{
    float: right;
    text-align: right;
    font-style: normal;
    font-weight: 700!important;
    font-size: 18px!important;
    color: #212B36!important;
}


.IconTextoTipoPagamentoEditar{
    float: right;
    text-align: right;
    font-style: normal;
    font-weight: 700!important;
    font-size: 13px!important;
    color: #00AB55!important;
    display: inline-flex; 
}

.TextoTotalValorPagamento{
    float: right;
    text-align: right;
    font-style: normal;
    font-weight: 800!important;
    font-size: 16px!important;
    color: #00AB55!important;
    display: inline-flex; 
}

.GridPagamentoBoletoGerado {
    height: 100%;
}

.TopoDialogNotificacaoPagamento{
    display: inline-flex;
    width: 100%;
}

.TopoDialogNotificacaoPagamentoIcons{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.TopoDialogNotificacaoPagamentoIcons button{
    background-color: #078DEE;
    color: #ffffff;
    
}

.TextoTituloDialogNotificacaoPagamento{
    font-weight: 700!important;
    font-size: 24px!important;
    line-height: 36px!important;
    color: #45515B!important;
}

.TextoSubTituloDialogNotificacaoPagamento{
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 24px!important;
    color: #637381!important;
}

.CopiarCodigoDialogNotificaPagamento{
    margin-top: 10px!important;
    width: 90vw!important;
    height: 3rem;
}


.GridPagamentoBoletoGerado{
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
    justify-content: center!important;
    height: 100%!important;
}



.ConteudoPagamentoPix{
    display: flex;
    flex-direction: column!important;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    box-shadow: 0 0 2px rgba(145, 158, 171, .2), 0 12px 24px -4px rgba(145, 158, 171, .12);
    height: min-content!important;
    margin: 8px;
    padding: 20px 0px;
}

@media (min-width: 767px){
    .TituloContribuirPagamento,
    .SubTituloContribuirPagamento,
    .SelecioneValorContribuirPagamento,
    .ConteudoContribuicaoTabPagamento{
        text-align: left!important;
    }

    .IconTextoTipoPagamento img {
        object-fit: contain;
    }

    .GridPagamentoBoletoGerado{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    

}