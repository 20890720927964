.CardFiltrarAtendente {
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin: 0px!important;
}


.CardInputFiltroPorNomeAtendente .MuiFormControl-root.MuiTextField-root.css-lavlqk-MuiFormControl-root-MuiTextField-root,
.CardInputFiltroPorAssuntoAtendente .css-p89yar-MuiAutocomplete-root
{
    width: 100%!important;
}

div#demo-simple-select {
    display: flex;
    align-items: center;
    width: 100%!important;
}

.IconsAtendentesFiltroMobile {
    width: 40px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.IconsAssuntosFiltroMobile {
    width: 40px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.GeneroScroll .IconFiltroBuscaGenero, 
.TipoDeProfissionalScroll .IconFiltroBuscaGenero{
    width: 6rem;
}

#InputFiltroPorNomeAtendente{
    padding: 10px;
    height: 55px;
}

.CardInputFiltroPorNomeAtendente {
    border-radius: 8px;
    margin: 10px 0;
}

.MuiTabs-root.IconsTipoProfissional.css-te4opc{
    height: 60px!important;
}

button#BotaoFiltroAtendente, #BotaoFiltroAtendenteNovaBusca {
    width: 100%;
    background: #3366FF;
    border-radius: 8px;
    color: #fff;
    margin: 20px 0;
}

button#BotaoFiltroAvancado {
    width: 100%;
    background: transparent;
    border-radius: 8px;
    color: #3366FF;
    border: 1px solid #3366FF;
}

.CardFiltroRolavelAssuntos .css-10jxku0{
    max-width: 100%!important;
    width: 100%!important;
}

.TituloCardFiltroAtendente{
    font-style: normal;
    font-weight: 600!important;
    font-size: 18px!important;
    color: #212B36;
    margin: 20px 0!important;
}

.BotaoVoltarCardFiltroAtendente{
    font-style: normal;
    font-weight: 600!important;
    font-size: 14px!important;
    color: #212B36;
    margin: 5px 0!important;
}

.SubTituloCardFiltroAtendente{
    font-style: normal;
    font-weight: 600!important;
    font-size: 11px!important;
    color: #212B36;
    margin-bottom: 10px;
}

.IconTipoProfissional, .IconFiltroBuscaAssunto{
    background: #DFE3E8;
    box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
    border-radius: 50px;
    color: grey;
}

.IconsFiltroBuscaAssunto .css-2aaidc, 
.IconsTipoProfissional .css-2aaidc{
    display: none;
}


button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorInherit.Mui-selected.IconFiltroBuscaAssunto.css-q4khxn {
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    transition-duration: .3s;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorInherit.Mui-selected.IconFiltroBuscaAssunto.css-q4khxn.Mui-selected {
    border-bottom: 1px solid #000;
    color: #000;    
    fill: #000;
    padding-bottom: 5px;
    transition-duration: .3s;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorInherit.IconFiltroBuscaAssunto.css-q4khxn:not(.Mui-selected):hover{
    border-bottom: 1px solid #717171;
    color: #000;    
    fill: #000;
    padding-bottom: 5px;
    transition-duration: .3s;
}

.IconFiltroBuscaAssunto img.MuiTab-iconWrapper{
 margin-right: 0px!important;
 margin-bottom: 4px!important;
}

.IconsTipoProfissional .css-rbs0n7:not(.Mui-selected),
.IconsFiltroBuscaAssunto .css-rbs0n7:not(.Mui-selected){
    background: #DFE3E8;
    box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
    border-radius: 50px;
    color: grey;
}

.IconsFiltroBuscaAssunto .css-g8hx08-MuiButtonBase-root-MuiTab-root.Mui-selected,
.IconsFiltroBuscaAssunto .css-rbs0n7.Mui-selected,
.IconsTipoProfissional .css-g8hx08-MuiButtonBase-root-MuiTab-root.Mui-selected, 
.IconsTipoProfissional .css-rbs0n7.Mui-selected{
    background: #3366FF;
    border-radius: 50px;
    color: #fff;
}


.TopoFiltroAvancadoAtendente{
    height: 62px;
    color: #45515B; 
    font-size: 20px;
    font-weight: 700;
    
}

.FiltroProfissionais{
    overflow-x: scroll;
    max-width: 100%;
}

.AssuntoScroll{
    margin: 20px 0px;
}

.FiltrosProfissionaleAssunto{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 8px;
    padding: 15px 10px;
    margin: 0px 10px;
}

.LineFiltroPrecoInputs .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root,
.FaixaDePrecoFiltroAvancado .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc,
.FaixaDeIdadeFiltroAvancado .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc
{
    width: 45%;
}

input#maxPrecoFiltroAvancao, 
input#minPrecoFiltroAvancao,
input#maxIdadeFiltroAvancao, 
input#minIdadeFiltroAvancao{
    background: rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    padding: 15px;
}

.IconsFiltroBuscaGenero .css-1dq6kro-MuiButtonBase-root-MuiTab-root:not(.Mui-selected){
    border: 1px solid rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    padding: 5px 20px;
}

.IconsFiltroBuscaGenero .css-1dq6kro-MuiButtonBase-root-MuiTab-root.Mui-selected{
    border: 1px solid rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    padding: 5px 20px;
}

.FaixaDePrecoFiltroAvancado,
.GeneroScroll,
.FaixaDeIdadeFiltroAvancado,
.Avaliacao {
    margin: 20px;
}

label.css-hlbajn-MuiRating-label span {
    border: 1px solid rgba(145, 158, 171, 0.16);
    margin: 0px 5px;
    padding: 4px 8px;
    height: 33px;
    border-radius: 6px;
}

.MuiTabs-root.IconsFiltroBuscaGenero.css-te4opc .css-1ngwh8e:not(.Mui-selected) {
    border: 1px solid rgba(145, 158, 171, 0.16);
    padding: 4px 8px;
    border-radius: 6px;
}

.Avaliacao .css-l5xv05{
    border: 1px solid rgba(145, 158, 171, 0.16);
    padding: 4px 8px;
    border-radius: 6px;
    margin: 0px 4px;
}

.Avaliacao .css-1t9pz9x {
    width: 1em;
    height: 1em;
}

.MuiTabs-root.IconsFiltroBuscaGenero.css-te4opc .css-1ngwh8e.Mui-selected{
    border: 1px solid #3366FF;
    color: #fff;
    padding: 4px 8px;
    border-radius: 6px;
    background-color: #3366FF;
}

.MuiTabs-root.IconsFiltroBuscaGenero.css-te4opc .css-2aaidc{
    display: none;
}

label.css-hlbajn-MuiRating-label span .css-1t9pz9x {
    width: 1em;
    height: 1em;
}



.btnBuscarFiltroAvancado{
    background-color: #3366FF!important;
    color: #fff!important;
    font-weight: 700!important;
    padding: 10px!important;
    width: 100%;
    margin: 10px 20px!important;
}

.btnRemoverFiltrosFiltroAvancado{
    color:  #45515B!important;
    font-weight: 700!important;
    text-decoration: underline!important;
}

.btnsFiltrosAvancados {
    padding: 20px 15px;
}

.btnsFiltrosAvancados .css-1lgsg1x-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textInherit.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorInherit.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textInherit.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorInherit.MuiButton-disableElevation.btnBuscarFiltroAvancado.css-19ds5pw {
    margin: 0!important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.btnRemoverFiltrosFiltroAvancado.css-lc49rk-MuiButtonBase-root-MuiButton-root,
.CardInputFiltroPorNomeAtendente .MuiFormControl-root.MuiTextField-root.css-1xdyj2e, button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.btnRemoverFiltrosFiltroAvancado.css-19gizus
{
    width: 100%;
}