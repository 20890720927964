.geralAgendamentoAtendenteOnOff{
    height: 100%;
}

.dialogAgendamento .MuiDialog-paperFullScreen {
    display: flex;
    align-items: center;
}

.geralAgendamentoHorarios{
    background: transparent!important;
    border: 2px solid rgba(255,255,255, 0.2);
    box-shadow: 0px 4px 11px rgba(145, 158, 171, 0.2), 0px 18px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 8px;
    margin: 10px 0;
    padding: 25px 25px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.CardConfirmacaoHorario{
    width: 100%!important;
    padding: 15px!important;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);

}

.CardConfirmacaoHorario p{
    color: #212B36;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.chipTipoAtendenteAnjo{
    background-color: #26cfff!important;
    color: #fff!important;
}

.chipTipoAtendentePsicologo{
    background-color: rgb(118, 53, 220)!important;
    color: #fff!important;
}

.chipTipoAtendenteTerapeuta{
    background-color: #ffbe98!important;
    color: #fff!important;
}

.chipTipoAtendenteCoach{
    background-color: #5dbfc0!important;
    color: #fff!important;
}

.CardConfirmacaoHorario .chipConfirmacaoHorario{
    border-radius: 8px;
    border: 1px solid var(--components-button-outlined, rgba(145, 158, 171, 0.32));
    color: #212B36;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.HorariosSugeridosTitulo{
    color:  #637381!important;
    font-family: Public Sans!important;
    font-size: 12px!important;
    font-style: normal!important;
    font-weight: 700!important;
    line-height: 18px!important;
    text-align: left!important;
}

.topoAgendamento{
    position: absolute;
    top: 1rem;
}

.sugestaoDoAtendenteHorario{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sugestaoDoAtendenteHorarioTitulo{
    color: #637381!important;
    text-align: left!important;
    font-family: Public Sans!important;
    font-size: 16px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: 24px!important;
}

.sugestaoDoAtendenteHorario p{
    color:  #212B36!important;
    font-size: 20px!important;
    font-style: normal!important;
    font-weight: 700!important;
    line-height: 36px!important;
}

.TopoAgendamentoProfile{
    padding: 2rem;
    display: flex;
    align-items: center;
}

.FotoeNomeAtendenteAgendamento{
    display: flex;
    align-items: center;
}

.btnAgendamento{
    margin: .4rem 0!important;
    text-transform: none!important;
}

.opcoesHorarios{
    max-width: 100%;
}


.tabsHorarios{
    max-width: 90vw;
    margin: 0!important;
    padding: 0!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    overflow-x: scroll!important;
}

.buttonGroupHorarios button{
    border-radius: 8px!important;
    border: 1px solid rgba(145, 158, 171, 0.32)!important;
    margin: 0px 10px!important;
    font-weight: 700!important;
    padding: 11px!important;
}

.HorariosAgendamentoTabs  .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX{
    overflow-x: scroll!important;
}

.tabsHorariosConfirmacao, .BoxHorariosDisponiveisAgendamento{
    width: 100%!important;
}

.HorariosAgendamentoTabs button{
    border-radius: 8px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    width: 4rem!important;
    margin-right: 0.8rem!important;
}

.HorariosAgendamentoTabs span{
    display: none;
}

.HorariosAgendamentoTabs button.Mui-selected{
    background-color: #36f;
    color: #fff!important;
}

.horariosAgendamento, .weekly-calendar{
    margin: 15px 0;
    padding: 1.4rem;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
}

.MesesdoAno{
    text-transform: capitalize!important;
}

.navigation{
    display: flex;
    margin-bottom: 10px;
}

.week, .weeksName{
    display: flex;
    justify-content: space-between;   
    height: 20px
}


.weekName, .day{
    text-align: center;
    width: 25px;
    z-index: 1;
    cursor: pointer;
    transition-duration: .25s;
}


 .dayold{
    text-align: center;
    width: 25px;
    z-index: 1;
    cursor: not-allowed;
}

.weekName{
    color: #bababa!important;
    font-size: 12px;
    z-index: 2;
}

.day:hover{
    background-color: rgba(51, 102, 255, 0.15);
    border-radius: 4px;
    border: 1px solid rgba(7, 141, 238, 0.5);
    color: #36f;
}

.current-day{
    background-color: #36f;
    color: #fff!important;
    border-radius: 25px;
    position: relative;
    height: 48px;
    z-index: 0;
    display: flex;
    padding-bottom: 5px;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
    border: none!important;
    top: -25px;
}
.select-day{
    background-color: #00A000!important;
    color: #fff!important;
    border-radius: 25px;
    position: relative;
    height: 48px;
    padding-bottom: 5px;
    z-index: 0!important;
    border: none!important;
    display: flex;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
    top: -25px;
}