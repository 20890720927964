.mainGeral{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.generalFooter{
    position: relative;
    top: 80px;
    padding: 20px;
    border-top: 1px solid rgba(145, 158, 171, 0.32);
    margin: 0px!important;
}

main.MuiBox-root.css-136y9jh {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 767px){
    .AtencaoFooter{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0;
    }
}