.CardHistoricoAcolhimentoRecorrente{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin: 15px 0px;
}

.HeaderHistoricoAcolhimentoRecorrente{
    padding: 10px 20px;
    background: #F4F6F8;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    text-align: left;
}

.HeaderHistoricoAcolhimentoRecorrente p{
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #45515B!important;
}

.InputSearchHistoricoAcolhimentoRecorrente{
    padding: 10px 20px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.InputBuscaporAnjoeFiltro.css-14i1p63-MuiInputBase-root-MuiOutlinedInput-root{
    width: 95%;
}

.MainHistoricoAcolhimentoRecorrente{
    padding: 10px;
}

.CardAzulDashInicialVersaoDesktop{
    background: linear-gradient(0deg, #3366ffc7, #3366ff2b), url(https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    padding: 25px;
    margin: 20px 0px;
    text-align: left;
}

.CardAzulDashInicialVersaoDesktop .TituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 600!important;
    font-size: 24px!important;
    line-height: 32px!important;
}

.CardAzulDashInicialVersaoDesktop .SubtituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    margin: 13px 0;
}

.CardAzulDashInicialVersaoDesktop .BtnCardAzulDashInicial{
    background: #FFFFFF;
    border-radius: 8px;
    color: #212B36;
    padding: 10.5px 14px;
}