.CardDivulgaPacoteDashboard{
    background: linear-gradient(135deg, rgb(7, 141, 238), rgb(3, 81, 171));;
    border-radius: 16px;
    margin-top: 80px;
    margin-bottom: 10px;
    height: 340px;
}

.CardDivulgaPacoteDashboard img {
    position: relative;
    top: -70px;
}

.CardDivulgaPacoteDashboard .TituloCardAzulDashInicialDivulgaPacote {
    color: #fff;
    font-size: 24px!important;
    font-style: normal;
    font-weight: 600!important;
    line-height: 32px!important;
    padding: 10px 40px;
    position: relative;
    top: -60px;
}

.CardDivulgaPacoteDashboard .SubtituloCardAzulDashInicialDivulgaPacote {
    color: #fff;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 400!important;
    line-height: 22px!important;
    padding: 10px 40px;
    position: relative;
    top: -60px;
}


@media (max-width: 767px) {
    .CardDivulgaPacoteDashboard{
        background: #2065D1;
        border-radius: 16px;
        margin-top: 80px;
        margin-bottom: 10px;
        height: max-content;
    }
}