.ConfirmarCadastroPage{
    height: 100vh;
    display: flex;
    
}

.ConfirmarCadastroPage .ImageConfirmarCadastro{
    width: 14rem;
}

.ConfirmarCadastroPage .TituloConfirmarCadastro{
    font-size: 2rem;
    color: #212B36;
}

.ConfirmarCadastroPage .TextoConfirmarCadastro{
    font-size: 1rem;
    color: #212B36;
}

@media (max-width: 767px){ 
    .ConfirmarCadastroPage .BtnAcessarAgoraConfirmarCadastro{
        width: 100%!important;
    }
}
