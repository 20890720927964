.BotaoCTAVerPerfil {
    height: 3rem!important;
    margin: 24px!important;
}

.CTAVerPerfilGrid {
    display: flex;
    flex-direction: column;
}

.VideoApresentacaoCard{
    margin: 10px 24px!important;
    background-color: #FFFFFF;
    color: #212B36;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: none;
    background-image: none;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    width: 100%!important;
}

.cardAvaliacoesVerPerfil{
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    background-image: url('vscode-file://vscode-app/c:/Users/ANDREI/AppData/Local/Programs/Microsoft%20VS%20Code/resources/app/out/vs/code/electron-sandbox/workbench/workbench.html./home/img/depoimentos-img.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin-top: 10px;
    width: 100%;
}

.TextoAvaliacaoVerPerfil {
    height: 100px; /* Altura específica */
    overflow: auto; /* Adicionar barra de rolagem quando o conteúdo ultrapassar a altura */
  }

  /* Barra de rolagem */
.TextoAvaliacaoVerPerfil::-webkit-scrollbar {
    width: 5px; /* Largura da barra de rolagem */
  }
  
  /* Alça da barra de rolagem */
  .TextoAvaliacaoVerPerfil::-webkit-scrollbar-thumb {
    background: #078DEE; /* Cor da alça */
    border-radius: 5px; /* Borda arredondada da alça */
  }
  
  /* Faixa da barra de rolagem */
  .TextoAvaliacaoVerPerfil::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor de fundo da faixa de rolagem */
  }
  
  /* Barra de rolagem no Firefox */
  .TextoAvaliacaoVerPerfil {
    scrollbar-width: thin;
    scrollbar-color: #36f #f1f1f1;
  }
.videoyoutube{
    margin: 10px;
}
.cardConversasSobre{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}

.cardConversasSobre h3{
    margin: 0px!important;
}

.GlobalCardsAvaliacoes .css-1nky8vc{
    background-color: transparent!important;
}

.NomeLocaleDepoimento {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    margin-left: 1rem;
}

.NomeLocaleDepoimento h3 {  
    margin: 0px!important;
    text-align: left;
}

.NomeLocaleDepoimento p {  
    text-align: left;
}

.LocalDepoimento{
    display:inline-flex ;
}

@media (min-width: 768px){
    .CTAVerPerfilGrid button {
        width: 100%;
    }
    .GlobalCardsAvaliacoes{
        margin-left: 20px!important;
    }
}