.DialogPaddingStep0SaladeEspera, .DialogPaddingAnjoChegouSaladeEspera, .CTAsSalaDeEsperaAnjoNaoChegou{
    padding: 0px 15px;
}

.DialogPaddingAnjoChegouSaladeEspera, .DialogPaddingAnjoNaoChegouSaladeEspera{
    height: inherit;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column.MainDialogSaladeEsperaAnjoChegou.css-1ewlecp-MuiGrid-root{
    display: inline-flex;
    flex-direction: column;
}

.ImagemSalaDeEspera1, .ImagemSalaDeEsperaAnjoChegou{
    width: 240px;
}

.IconCheckAnjoChegouSalaDeEspera{
    color:#00AB55;
}
.textoarea{
   width: 100%;
}

.TopoSaladeEspera .IconBackTopoSaladeEspera{
    width: 25px;
    height: 25px;
}

.empresasParceiras{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empresasParceiras img{
    margin: 3rem 0;
    width: 50%;
}


.TituloSalaDeEsperaSlide01, .TituloSalaDeEsperaAnjoChegou, .TituloSalaDeEsperaAnjoNaoChegou{
    width: 85%;
    margin: 10px 0!important;
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 24px!important;
    line-height: 36px!important;
    text-align: center!important;
    color: #45515B!important;
}

.SubTituloSalaDeEsperaSlide01, .SubTituloSalaDeEsperaAnjoChegou, .SubTituloSalaDeEsperaAnjoNaoChegou {
    width: 75%;
    margin: 10px 0!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 24px!important;
    text-align: center!important;
    color: #637381!important;
}

.TextoApoioSalaDeEsperaSlide01{
    width: 75%;
    margin: 10px 0!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    text-align: center!important;
    color: #637381!important;
}

.FooterButtonSalaDeEsperaSlider01, .FooterButtonSalaDeEsperaAnjoChegou{
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 2rem;
    padding: 0px 15px;
    height: 3rem;
}

.GlobalSaladeEsperaSteppereSteps, .CardTimerSaladeEspera, .CardAtendenteChegouSaladeEspera{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin: 5px 15px;
    padding: 15px 20px;
}



.ButtonSalaDeEsperaSlider01, .ButtonSalaDeEsperaAnjoChegou{
    width: 100%!important;
    background: #3366FF!important;
    border-radius: 8px!important;
    color: #FFF!important;
    height: 3rem;
}

.ButtonOutlineSalaDeEsperaAnjoNaoChegou{
    border: solid 1px #3366FF!important;
    background-color: transparent;
    border-radius: 8px!important;
    color: #3366FF!important;
    padding: 5px 10px!important;
}

.ButtonSalaDeEsperaAnjoNaoChegou {
    margin: 12px 0px!important;
    padding: 10px 5px;
}

.TituloStep1SaladeEspera{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
    color: #45515B!important;
}

.IconAlertaStep2SaladeEspera{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #637381!important;
}

.TextoAlertaStep2SaladeEspera{
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 18px!important;
}

.StepseBotoesSaladeEspera, .Pergunta1,.Pergunta2,.Pergunta3{
    padding: 20px 0;
}

.ButtonNextFinishSaladeEspera {
    width: 100%;
    background: #3366FF!important;
    border-radius: 8px!important;
    color: #fff!important;
    height: 3rem;
}

.AlternativasPerguntasStep1SaladeEspera{
    width: 80px!important;
    height: 20px!important;
    border: 1px solid #3366FF!important;
    border-radius: 8px!important;
}

.Step3SaladeEspera img{
    filter: grayscale(1) ;
    opacity: 0.6;
    transform: scale(0.9);
    transition-duration: .3s;
}

.Step3SaladeEspera img:hover{
    filter: grayscale(0) ;
    opacity: 1;
    transform: scale(1);
}

.FotoeTituloAnjoChegouSaladeEspera{
    display: inline-flex;
}

.CardAtendenteChegouSaladeEspera{
    display: inline-flex;
    width: 90%;
    justify-content: space-between;
}

.TituloeTextoAtendenteChegouSaladeEspera {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.StepseBotoesSaladeEspera {
    height: 100%!important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.BotaoIrParaoChatSaladeEspera{
    width: 100px!important;
    background: #3366FF!important;
    border: 1px solid #3366FF!important;
    border-radius: 8px!important;
    color: #fff!important;
    font-size: 12px!important;
}

.TituloTimerSaladeEspera{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 12px!important;
    line-height: 18px!important;
    text-transform: uppercase!important;
    color: #637381!important;
    mix-blend-mode: normal!important;
}

.TituloAtendenteChegouSaladeEspera{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 12px!important;
    line-height: 18px!important;
    text-transform: uppercase!important;
    color: #00AB55!important;
    mix-blend-mode: normal!important;
}

.SubTituloAtendenteChegouSaladeEspera{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 18px!important;
    color: #637381!important;
    mix-blend-mode: normal!important;
}

.IconRelogioeTituloSaladeEspera {
    display: inline-flex;
    align-items: center;
}

.TimerSaladeEspera{
    color: #212B36!important;
    font-size: 24px!important;
    font-weight: 700!important;
}

.ProgressoCard {
    margin-top: 15px;
}

.TituloStep4SaladeEspera{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
    color: #45515B!important;
}

.SubTituloStep4SaladeEspera{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #45515B!important;
    margin-bottom: 10px!important;
}

@media (max-width: 767px){
    .CardAtendenteChegouSaladeEspera, .CardTimerSaladeEspera{
        width: 92%;
        overflow-x: hidden;
    }

    .empresasParceiras img{
        margin: 3rem 0;
        width: 100%;
    }
   
}

@media (min-width: 767px){
    .CardAtendenteChegouSaladeEspera, .CardTimerSaladeEspera{
        width: 98%;
    }
}