.CardsInfoRemarcacao{
    padding: 20px 25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}

.AgendamentoRemarcacaoDados{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.buttonsBotaoMaisOpcoesAgendamentoRemarcadoRecusar:hover{
    background-color: #f1c959!important;
    color: #7a4100!important;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
}

.buttonsBotaoMaisOpcoesAgendamentoRemarcadoAceitar:hover{
    background-color: #ea9c00!important;
    color: #fff!important;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
}