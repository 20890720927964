.CardFiltrarAssunto{
    padding: 20px 25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin: 15px 5px;
}


.CardInputFiltroPorNomeAtendente .MuiFormControl-root.MuiTextField-root.css-lavlqk-MuiFormControl-root-MuiTextField-root,
.CardInputFiltroPorAssuntoAtendente .css-p89yar-MuiAutocomplete-root
{
    width: 100%!important;
}

#InputFiltroPorNomeAtendente{
    padding: 10px;
    height: 55px;
}

.IconsFiltroBuscaAssunto button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorInherit.IconFiltroBuscaAssunto.css-q4khxn{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-shadow: none;
}

.FiltroProfissionais{
    overflow-x: scroll;
    max-width: 100%;
}

.FiltroProfissionais button{
    padding: 10px 30px;
}

.CardFiltrarAssunto .css-1vhn2wi-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped{
    width: 145px;
}

.FiltroProfissionais::-webkit-scrollbar-track
{
    height: 2px;
    width: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.FiltroProfissionais::-webkit-scrollbar
{
    height: 4px;
    width: 2px;
	background-color: #F5F5F5;
}

.FiltroProfissionais::-webkit-scrollbar-thumb
{
    height: 2px;
    width: 2px;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #078dee;
}



#toggleButtonGroup button{
    width: fit-content!important;
}

.SlideDivFiltroAssuntos{
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

@media (max-width: 767px){
    .SlideDivFiltroAssuntos{
        width: 60px;
    }
}

.SlideDivFiltroAssuntos img{
    opacity: 0;
    transition: opacity 0.5s ease;
    height: fit-content;
}

.CardFiltrarAssunto:hover .SlideDivFiltroAssuntos img{
    opacity: 0.6;
}


.CardInputFiltroPorNomeAtendente {
    border-radius: 8px;
    margin: 10px 0;
}

.MuiTabs-root.IconsTipoProfissional.css-te4opc{
    height: 60px!important;
}

button#BotaoFiltroAtendente, #BotaoFiltroAtendenteNovaBusca {
    width: 100%;
    background: #3366FF;
    border-radius: 8px;
    color: #fff;
    margin: 20px 0;
}

button#BotaoFiltroAvancado {
    width: 100%;
    background: transparent;
    border-radius: 8px;
    color: #3366FF;
    border: 1px solid #3366FF;
}

.CardFiltroRolavelAssuntos .css-10jxku0{
    max-width: 100%!important;
    width: 100%!important;
}

.TituloCardFiltroAtendente{
    font-style: normal;
    font-weight: 700!important;
    font-size: 24px!important;
    color: #212B36;
    margin: 5px 0!important;
}

.CardFiltrarAssunto p{
    text-align: start;
    margin-bottom: 15px;
}

.SubTituloCardFiltroAtendente{
    font-style: normal;
    font-weight: 600!important;
    font-size: 11px!important;
    color: #212B36;
    margin-bottom: 0.5rem!important;
}

.IconTipoProfissional, .IconFiltroBuscaAssunto{
    background: #DFE3E8;
    box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
    border-radius: 50px;
    color: grey;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorInherit.Mui-selected.IconFiltroBuscaAssunto.css-q4khxn {
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    transition-duration: .3s;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorInherit.Mui-selected.IconFiltroBuscaAssunto.css-q4khxn.Mui-selected {
    border-bottom: 1px solid #000;
    color: #000;    
    fill: #000;
    padding-bottom: 5px;
    transition-duration: .3s;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorInherit.IconFiltroBuscaAssunto.css-q4khxn:not(.Mui-selected):hover{
    border-bottom: 1px solid #717171;
    color: #000;    
    fill: #000;
    padding-bottom: 5px;
    transition-duration: .3s;
}

.IconFiltroBuscaAssunto img.MuiTab-iconWrapper{
 margin-right: 0px!important;
 margin-bottom: 4px!important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected.IconFiltroBuscaGenero.css-1ngwh8e {
    background-color: #36f;
    border: 1px solid #36f;
    border-radius: 6px;
    color: #fff;
    padding: 4px 8px;
    width: 5rem;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.IconFiltroBuscaGenero.css-1ngwh8e{
    border: 1px solid rgba(145,158,171,.16);
    border-radius: 6px;
    padding: 4px 8px;
    width: 5rem;
}


.IconsFiltroBuscaAssunto .css-rbs0n7:not(.Mui-selected){
    background: #DFE3E8;
    box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
    border-radius: 50px;
    color: grey;
}

.IconsFiltroBuscaAssunto .css-g8hx08-MuiButtonBase-root-MuiTab-root.Mui-selected,
.IconsFiltroBuscaAssunto .css-rbs0n7.Mui-selected{
    background: #3366FF;
    border-radius: 50px;
    color: #fff;
}


.TopoFiltroAvancadoAtendente{
    height: 62px;
    color: #45515B;
    font-size: 20px;
    font-weight: 700;
    
}


.TipoDeProfissionalScroll p {
    text-align: start;
    margin-bottom: 0.5rem;
}


.AssuntoScroll{
    margin: 20px 0px;
}


.LineFiltroPrecoInputs .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root,
.FaixaDePrecoFiltroAvancado .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc,
.FaixaDeIdadeFiltroAvancado .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc
{
    width: 45%;
}

input#maxPrecoFiltroAvancao, 
input#minPrecoFiltroAvancao,
input#maxIdadeFiltroAvancao, 
input#minIdadeFiltroAvancao{
    background: rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    padding: 15px;
}

.IconsFiltroBuscaGenero .css-1dq6kro-MuiButtonBase-root-MuiTab-root:not(.Mui-selected){
    border: 1px solid rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    padding: 5px 20px;
}

.IconsFiltroBuscaGenero .css-1dq6kro-MuiButtonBase-root-MuiTab-root.Mui-selected{
    border: 1px solid rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    padding: 5px 20px;
}

.FaixaDePrecoFiltroAvancado,
.GeneroScroll,
.FaixaDeIdadeFiltroAvancado,
.Avaliacao {
    margin: 20px;
}

label.css-hlbajn-MuiRating-label span {
    border: 1px solid rgba(145, 158, 171, 0.16);
    margin: 0px 5px;
    padding: 4px 8px;
    height: 33px;
    border-radius: 6px;
}

.MuiTabs-root.IconsFiltroBuscaGenero.css-te4opc .css-1ngwh8e:not(.Mui-selected) {
    border: 1px solid rgba(145, 158, 171, 0.16);
    padding: 4px 8px;
    border-radius: 6px;
}

.Avaliacao .css-l5xv05{
    border: 1px solid rgba(145, 158, 171, 0.16);
    padding: 4px 8px;
    border-radius: 6px;
    margin: 0px 4px;
}

.Avaliacao .css-1t9pz9x {
    width: 1em;
    height: 1em;
}

.MuiTabs-root.IconsFiltroBuscaGenero.css-te4opc .css-1ngwh8e.Mui-selected{
    border: 1px solid #3366FF;
    color: #fff;
    padding: 4px 8px;
    border-radius: 6px;
    background-color: #3366FF;
}



label.css-hlbajn-MuiRating-label span .css-1t9pz9x {
    width: 1em;
    height: 1em;
}



.btnBuscarFiltroAvancado{
    background-color: #3366FF!important;
    color: #fff!important;
    font-weight: 700!important;
    padding: 10px!important;
    width: 100%;
    margin: 10px 20px!important;
}

.btnRemoverFiltrosFiltroAvancado{
    color:  #45515B!important;
    font-weight: 700!important;
    text-decoration: underline!important;
}

.btnsFiltrosAvancados {
    padding: 20px 15px;
}

.btnsFiltrosAvancados .css-1lgsg1x-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textInherit.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorInherit.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textInherit.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorInherit.MuiButton-disableElevation.btnBuscarFiltroAvancado.css-19ds5pw {
    margin: 0!important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.btnRemoverFiltrosFiltroAvancado.css-lc49rk-MuiButtonBase-root-MuiButton-root,
.CardInputFiltroPorNomeAtendente .MuiFormControl-root.MuiTextField-root.css-1xdyj2e, button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.btnRemoverFiltrosFiltroAvancado.css-19gizus
{
    width: 100%;
}
@media (max-width: 767px){
    .TituloCardFiltroAtendente{
        font-style: normal;
        font-weight: 700!important;
        font-size: 20px!important;
        color: #212B36;
        margin: 5px 0!important;
    }

    .AssuntoScroll .css-te4opc .MuiTabs-scrollButtons,
    .AssuntoScroll .css-v3rcql-MuiTabs-root .MuiTabs-scrollButtons{
        display: none;
    }
}