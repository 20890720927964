.CardContribuaDashboard .TituloCardAzulDashInicialContribua {
    color: #fff;
    font-size: 24px!important;
    font-style: normal;
    font-weight: 600!important;
    line-height: 32px!important;
}

.CardContribuaDashboard .SubtituloCardAzulDashInicialContribua {
    color: #fff;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 400!important;
    line-height: 22px!important;
    margin: 13px 0;
}

.CardContribuaDashboard .SelecioneoValorContribua {
    color: #fff;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 400!important;
    line-height: 22px!important;
    
}

.CardContribuaDashboard .OpcoesValorContribua{
    background: transparent!important;
}

.CardContribuaDashboard .OpcoesValorContribua button{
    color: #fff!important;
    border: 1px solid #fff;
}

.CardContribuaDashboard .OpcoesValorContribua button.Mui-selected{
    background-color: #fff!important;
    color: #36f!important;
}

.CardContribuaDashboard .OutroValorInputContribuaTitulo fieldset{
    border: 1px solid #fff;
}

.CardContribuaDashboard .MuiFormControl-root.MuiFormControl-fullWidth.css-1w4vsez-MuiFormControl-root,
.CardContribuaDashboard .MuiFormControl-root.MuiFormControl-fullWidth.css-dms7x9{
    margin: 10px 0!important;
}



.CardContribuaDashboard .OutroValorInputContribuaTitulo legend span, 
.CardContribuaDashboard input#outlined-uncontrolled,
.CardContribuaDashboard label#outlined-uncontrolled-label{
    color: #fff!important;
}

@media (max-width: 767px){
    .CardContribuaDashboard .MuiFormControl-root.MuiFormControl-fullWidth.css-1w4vsez-MuiFormControl-root {
        margin: 10px 0;
    }

    .CardContribuaDashboard .TituloCardAzulDashInicialContribua, 
    .CardContribuaDashboard .SubtituloCardAzulDashInicialContribua,
    .CardContribuaDashboard .SelecioneoValorContribua{
        text-align: center;
    }

    .CardContribuaDashboard .OpcoesValorContribua{
        display: flex;
        justify-content: center;
    }

    
}