.NomeAtendenteAtendentesHistoricoAcolhimentoRecorrente{
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
}

.DataAtendentesHistoricoAcolhimentoRecorrente{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #637381!important;
}   

.DadosAtendenteAtendimentoRecorrente{
    display: flex;
}

.StatusAtendentesHistoricoAcolhimentoRecorrente{
    background: rgba(0, 171, 85, 0.16);
    border-radius: 6px;
    color: #007B55;
    padding: 3px;
    font-weight: 700;
    font-size: 12px;
}

.StatusEBotaoMais_AtendentesHistoricoAcolhimentoRecorrente{
    display: inline-flex;
}

.BotaoMais_AtendentesHistoricoAcolhimentoRecorrente{
    width: 35px!important;
    min-width: 35px!important;
    padding: 0px!important;
}