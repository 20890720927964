.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root {
    /*width: 100vw!important;
    max-width: 100vw;*/
    width: 99vw;
    max-width: 100vw;
    margin: .5%!important;
    border-radius: 20px;
}

.containerInterno {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Photo {
    height: 120px;
    width: 120px;
    background-image: url('https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg');
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 20px;
}

.css-10pl5hw{
    background-color: #fff!important;
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw!important;
    position: absolute;
    left: 0;
}


.InfosChamadaEncerrada {
    margin: 20px 0px;
}

.InfosChamada {
    text-align: center;
    margin: 10px 0px;
}

.textoChamadaEncerrada{
    margin: 40px 0;
    text-align: center;
}

.InfosChamadaEncerrada .DesejaEncerrarChamada{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #45515B;
}

.InfosChamada .NomeUserEncerraAudio{
    color: #45515B;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.InfosChamada .TituloChamada{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #45515B;
}

.ItemsHeaderAudio .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root{
    color: #637381;
}

.headerAudio .botao{
    background-color: #DFE3E8;
    padding: 10px;
    border-radius: 50%;
    border: none;
    color: #212B36;
    height: 40px;
    width: 40px;
}



.botaoFooterSalaAudio{
    background-color: #DFE3E8;
    padding: 15px;
    border-radius: 50%;
    border: none;
    color: #212B36;
    transition-duration: 0.8s;
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.botaoFooterSalaAudio:hover{
    transform: rotate(360deg);
    background-color: #2f90ff;
    color: #fff;
}

.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root{
    overflow: hidden;
}

.botaoFooterTurnOff{
    background-color: #FF5630;
    padding: 15px;
    border-radius: 50%;
    border: none;
    color: #fff;
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.botaoFooterTurnOff:hover{
    transition-duration: 0.8s;
    background-color: #b02606;
    transform: translateY(-10px) scale(1.1);
}

.css-17nit7w-MuiPaper-root-MuiDialog-paper, .css-19r6kue-MuiContainer-root{
    margin: 0!important;
}

.css-19r6kue-MuiContainer-root{
    padding: 0!important;
}

.BarraMicrofoneAtivo1,.BarraMicrofoneAtivo2,.BarraMicrofoneAtivo3{
    background-color: #fff;
    z-index: 1;
    position: absolute;
    border-radius: 4px;
}

.BarraMicrofoneAtivo1{
    width: 4px;
    height: 15px;
    bottom: 10px;
    right: 10px;
}

.BarraMicrofoneAtivo2{
    width: 4px;
    height: 10px;
    bottom: 10px;
    right: 18px;
}

.BarraMicrofoneAtivo3{
    width: 4px;
    height: 5px;
    bottom: 10px;
    right: 26px;
}

.css-c4i6sg{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100vw!important;
}

.headerAudio{
    width: 100%;
    position: fixed;
    z-index: 1;
    padding: 10px;
}
.rodapeAudio{
    width: 100%;
    position: fixed;
    z-index: 1;
    padding: 10px;
    bottom: 0px;
    background-color: transparent;
}

.MuiContainer-root.MuiContainer-maxWidthXl.GlobalChamadaEncerrada.css-1ekb41w .MuiBox-root.css-c4i6sg {
    position: absolute;
    left: 0;
}

.rodapeAudioAtivo{
    width: 99%;
    margin: auto;
    position: fixed;
    z-index: 1;
    padding: 10px;
    bottom: 0px;
    background-color: #00000040;
    left: 0.5%;
    margin-bottom: .5%;
    border-radius: 0px 0px 20px 20px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root{
    background: transparent;
}

.GreyMaior{
    width: 5rem;
    border-radius: 50%;
    background: #EAEAEA;
    height: 5rem;
    animation: crescendo 1s alternate infinite ease-in;
}
#publisheraudio{
    display: none;
}
#subscriberaudio{
    display: none;
}
.GreyMenor{
    width: 3.5rem;
    border-radius: 50%;
    background: #CCCCCC;
    height: 3.5rem;
    animation: crescendo 1s alternate infinite ease-in;
    display: flex;
    align-items: center;
    color: #fff;
}

.IconCall {
    width: 100%;
    height: 20vh;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Cronometro {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Tempo {
    margin-bottom: 45px;
}

button.EncerrarBotaoAudio {
    background: #FF5630;
    border-radius: 8px;
    border: none;
    width: 50%;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 767px){

    .css-1uoov4x{
        margin: 0px!important;
    }

    .containerInterno{
        padding: 10px;
    }
    
        #publisher{
            width: 100%;
            border-radius: 15px;
        }

        .GlobalChamadaEncerrada .containerInterno {
            margin-top: -40px!important;
        }
        
    .MinhaCam{
        position: relative;
        width: 15rem;
        border-radius: 15px;
        background-position: center;
        background-size: cover;
        height: 20rem;
        display: inline-flex;
        justify-content: center;
        transition-duration: 0.8s;
    }

    .MinhaCam:hover{
        transform: rotate(3deg);
    }

    .TituloAguardando{
        position: absolute;
        z-index: 1;
        top: 9%;
        margin: auto;
        text-align: center;
        font-size: 18px!important;
        font-weight: 700!important;
        color: #45515B!important;
    }
    
    .SubTituloAguardando{
        position: absolute;
        z-index: 1;
        top: 12%!important;
        margin: auto;
        text-align: center;
        font-size: 18px!important;
        font-weight: 400!important;
        color: #637381!important;
    }
    
    .Tempo{
        position: absolute;
        z-index: 1;
        bottom: 14%!important;
        margin: auto;
        text-align: center;
        font-size: 18px!important;
        font-weight: 400!important;
        color: #637381!important;
    }

    button.EncerrarBotaoAudio {
        background: #FF5630;
        border-radius: 8px;
        border: none;
        width: 90%;
        display: inline-flex;
        padding: 10px;
        justify-content: center;
        color: #fff;
        position: absolute;
        cursor: pointer;
        bottom: 5%;
    }
    .timer{
        z-index: 1;
        margin: auto;
        text-align: center;
        font-size: 18px!important;
        font-weight: 400!important;
        color: #637381!important;
    }

    .digits{
        font-size: 18px;
        color: #637381!important;
    }

    .mili-sec{
        color: #637381!important;
    }


}