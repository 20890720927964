.MainCadastro {
    display: flex!important;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.MuiFormControl-root.MuiFormControl-fullWidth.css-1w4vsez-MuiFormControl-root {
    width: -webkit-fill-available;
}

.imageLeftRegisterRS{
    background: rgb(62,152,255);
    background: linear-gradient(340deg, rgba(62,152,255,1) 0%, rgba(255,220,96,1) 30%);
}

.MainCadastroGoogleRS{
    display: flex!important;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 0rem;
    background-color: #82cefd;
}

.Pais_Cadastro .ReactFlagsSelect-module_selectBtn__19wW7{
    height: 55px;
    padding: 16.5px 14px;
    border-radius: 8px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    font-size: 16px;
}
.Pais_Cadastro span.ReactFlagsSelect-module_selectValue__152eS{
    color: #98a6b0;
    padding: 0px;
}

.ItensMainCadastro{
    display: flex;
    flex-direction: column!important;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.ItensMainCadastroGoogleRS{
    width: 800px!important;
}



@media (max-width: 767px){
    .ItensMainCadastroGoogleRS{
        width: 100vw!important;
        padding: 10px;
    }

    .cardMainRegisterRS{
        margin: 10px;
    }

    .MainCadastroGoogleRS{
        display: flex !important;
        justify-content: flex-start;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.BotaoCadastreSe.css-1w11epi-MuiPaper-root {
        width: 100vw;
    }
}

.TopoCadastre{
    width: 100%;
    display: flex!important;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}



.LogoCadastre{
    width: 5rem;
    padding: 1rem 0rem;
    cursor: pointer!important;
}

.NotificaErrorIcon{
    color: #FF5630;
    width: 25px;
    height: 25px;
}

.TituloCadastre{
    font-size: 1.5rem!important;
    font-weight: 700!important;
    color: #212B36!important;
}


.EmailCadastroButton {
    background-color: #078DEE!important;
    color: #fff!important;
    height: 3.8rem;
    width: 20rem!important;
}



.EmailCadastroButton:hover {
    background-color: #2298ed!important;
    color: #fff!important;
}

.AppleCadastroButton{
    background-color: #141414!important;
    color: #fff!important;
    height: 3.8rem;
    width: 20rem!important;
}



.AppleCadastroButton:hover{
    background-color: #212121!important;
    color: #fff!important;
    
}

.GoogleCadastroButton{
    border: 1px solid #078DEE!important;
    height: 3.8rem;
    width: 20rem!important;
}

.AppleCadastroButton svg, .EmailCadastroButton svg, .GoogleCadastroButton svg{
    margin-right: .5rem;
}


.FazerLoginButton{
    border-bottom: 1px solid #078DEE!important;
    border-radius: 0px!important;
    text-transform: none!important;
}

@media (max-width: 900px){
    .heroCadasterBackground{
        display: none!important;
    }

   
}